/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ArchivalStatus } from './ArchivalStatus';
import {
    ArchivalStatusFromJSON,
    ArchivalStatusFromJSONTyped,
    ArchivalStatusToJSON,
} from './ArchivalStatus';

/**
 * 
 * @export
 * @interface CdrVersion
 */
export interface CdrVersion {
    /**
     * 
     * @type {string}
     * @memberof CdrVersion
     */
    cdrVersionId: string;
    /**
     * 
     * @type {string}
     * @memberof CdrVersion
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CdrVersion
     */
    accessTierShortName: string;
    /**
     * 
     * @type {ArchivalStatus}
     * @memberof CdrVersion
     */
    archivalStatus?: ArchivalStatus;
    /**
     * 
     * @type {boolean}
     * @memberof CdrVersion
     */
    hasWgsData?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CdrVersion
     */
    hasFitbitData?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CdrVersion
     */
    hasCopeSurveyData?: boolean;
    /**
     * Milliseconds since the UNIX epoch. This timestamp is derived from the deid dataset name. Ex - deid_output_20191004 -> 2019-10-04 00:00:00Z
     * @type {number}
     * @memberof CdrVersion
     */
    creationTime: number;
    /**
     * 
     * @type {boolean}
     * @memberof CdrVersion
     */
    hasFitbitSleepData?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CdrVersion
     */
    hasSurveyConductData?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CdrVersion
     */
    bigqueryDataset: string;
    /**
     * 
     * @type {boolean}
     * @memberof CdrVersion
     */
    tanagraEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CdrVersion
     */
    hasFitbitDeviceData?: boolean;
    /**
     * The public release number for the CDR version. This is consistent across tiers and environments unlike cdrVersionId
     * @type {number}
     * @memberof CdrVersion
     */
    publicReleaseNumber?: number;
}

/**
 * Check if a given object implements the CdrVersion interface.
 */
export function instanceOfCdrVersion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cdrVersionId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "accessTierShortName" in value;
    isInstance = isInstance && "creationTime" in value;
    isInstance = isInstance && "bigqueryDataset" in value;

    return isInstance;
}

export function CdrVersionFromJSON(json: any): CdrVersion {
    return CdrVersionFromJSONTyped(json, false);
}

export function CdrVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CdrVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cdrVersionId': json['cdrVersionId'],
        'name': json['name'],
        'accessTierShortName': json['accessTierShortName'],
        'archivalStatus': !exists(json, 'archivalStatus') ? undefined : ArchivalStatusFromJSON(json['archivalStatus']),
        'hasWgsData': !exists(json, 'hasWgsData') ? undefined : json['hasWgsData'],
        'hasFitbitData': !exists(json, 'hasFitbitData') ? undefined : json['hasFitbitData'],
        'hasCopeSurveyData': !exists(json, 'hasCopeSurveyData') ? undefined : json['hasCopeSurveyData'],
        'creationTime': json['creationTime'],
        'hasFitbitSleepData': !exists(json, 'hasFitbitSleepData') ? undefined : json['hasFitbitSleepData'],
        'hasSurveyConductData': !exists(json, 'hasSurveyConductData') ? undefined : json['hasSurveyConductData'],
        'bigqueryDataset': json['bigqueryDataset'],
        'tanagraEnabled': !exists(json, 'tanagraEnabled') ? undefined : json['tanagraEnabled'],
        'hasFitbitDeviceData': !exists(json, 'hasFitbitDeviceData') ? undefined : json['hasFitbitDeviceData'],
        'publicReleaseNumber': !exists(json, 'publicReleaseNumber') ? undefined : json['publicReleaseNumber'],
    };
}

export function CdrVersionToJSON(value?: CdrVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cdrVersionId': value.cdrVersionId,
        'name': value.name,
        'accessTierShortName': value.accessTierShortName,
        'archivalStatus': ArchivalStatusToJSON(value.archivalStatus),
        'hasWgsData': value.hasWgsData,
        'hasFitbitData': value.hasFitbitData,
        'hasCopeSurveyData': value.hasCopeSurveyData,
        'creationTime': value.creationTime,
        'hasFitbitSleepData': value.hasFitbitSleepData,
        'hasSurveyConductData': value.hasSurveyConductData,
        'bigqueryDataset': value.bigqueryDataset,
        'tanagraEnabled': value.tanagraEnabled,
        'hasFitbitDeviceData': value.hasFitbitDeviceData,
        'publicReleaseNumber': value.publicReleaseNumber,
    };
}

