/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Research plan for American Indian or Alaska Native participants
 * @export
 */
export const AIANResearchType = {
    EXCLUSIVE_AI_AN_POPULATION: 'EXCLUSIVE_AI_AN_POPULATION',
    CASE_CONTROL_AI_AN: 'CASE_CONTROL_AI_AN',
    FINDINGS_BY_AI_AN: 'FINDINGS_BY_AI_AN',
    NO_AI_AN_ANALYSIS: 'NO_AI_AN_ANALYSIS'
} as const;
export type AIANResearchType = typeof AIANResearchType[keyof typeof AIANResearchType];


export function AIANResearchTypeFromJSON(json: any): AIANResearchType {
    return AIANResearchTypeFromJSONTyped(json, false);
}

export function AIANResearchTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIANResearchType {
    return json as AIANResearchType;
}

export function AIANResearchTypeToJSON(value?: AIANResearchType | null): any {
    return value as any;
}

