/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AIANResearchType } from './AIANResearchType';
import {
    AIANResearchTypeFromJSON,
    AIANResearchTypeFromJSONTyped,
    AIANResearchTypeToJSON,
} from './AIANResearchType';
import type { DisseminateResearchEnum } from './DisseminateResearchEnum';
import {
    DisseminateResearchEnumFromJSON,
    DisseminateResearchEnumFromJSONTyped,
    DisseminateResearchEnumToJSON,
} from './DisseminateResearchEnum';
import type { ResearchOutcomeEnum } from './ResearchOutcomeEnum';
import {
    ResearchOutcomeEnumFromJSON,
    ResearchOutcomeEnumFromJSONTyped,
    ResearchOutcomeEnumToJSON,
} from './ResearchOutcomeEnum';
import type { SpecificPopulationEnum } from './SpecificPopulationEnum';
import {
    SpecificPopulationEnumFromJSON,
    SpecificPopulationEnumFromJSONTyped,
    SpecificPopulationEnumToJSON,
} from './SpecificPopulationEnum';

/**
 * 
 * @export
 * @interface ResearchPurpose
 */
export interface ResearchPurpose {
    /**
     * 
     * @type {string}
     * @memberof ResearchPurpose
     */
    aianResearchDetails?: string;
    /**
     * 
     * @type {AIANResearchType}
     * @memberof ResearchPurpose
     */
    aianResearchType?: AIANResearchType;
    /**
     * 
     * @type {string}
     * @memberof ResearchPurpose
     */
    additionalNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPurpose
     */
    approved?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPurpose
     */
    ancestry: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResearchPurpose
     */
    anticipatedFindings: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPurpose
     */
    commercialPurpose: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPurpose
     */
    controlSet: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPurpose
     */
    diseaseFocusedResearch: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResearchPurpose
     */
    diseaseOfFocus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPurpose
     */
    drugDevelopment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPurpose
     */
    educational: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResearchPurpose
     */
    intendedStudy: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchPurpose
     */
    scientificApproach: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPurpose
     */
    methodsDevelopment: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResearchPurpose
     */
    otherPopulationDetails?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPurpose
     */
    otherPurpose: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResearchPurpose
     */
    otherPurposeDetails?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPurpose
     */
    ethics: boolean;
    /**
     * 
     * @type {Array<SpecificPopulationEnum>}
     * @memberof ResearchPurpose
     */
    populationDetails?: Array<SpecificPopulationEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPurpose
     */
    populationHealth: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResearchPurpose
     */
    reasonForAllOfUs?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPurpose
     */
    reviewRequested: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResearchPurpose
     */
    socialBehavioral: boolean;
    /**
     * Milliseconds since the UNIX epoch.
     * @type {number}
     * @memberof ResearchPurpose
     */
    timeRequested?: number;
    /**
     * Milliseconds since the UNIX epoch.
     * @type {number}
     * @memberof ResearchPurpose
     */
    timeReviewed?: number;
    /**
     * 
     * @type {Array<DisseminateResearchEnum>}
     * @memberof ResearchPurpose
     */
    disseminateResearchFindingList?: Array<DisseminateResearchEnum>;
    /**
     * 
     * @type {string}
     * @memberof ResearchPurpose
     */
    otherDisseminateResearchFindings?: string;
    /**
     * 
     * @type {Array<ResearchOutcomeEnum>}
     * @memberof ResearchPurpose
     */
    researchOutcomeList?: Array<ResearchOutcomeEnum>;
}

/**
 * Check if a given object implements the ResearchPurpose interface.
 */
export function instanceOfResearchPurpose(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ancestry" in value;
    isInstance = isInstance && "anticipatedFindings" in value;
    isInstance = isInstance && "commercialPurpose" in value;
    isInstance = isInstance && "controlSet" in value;
    isInstance = isInstance && "diseaseFocusedResearch" in value;
    isInstance = isInstance && "drugDevelopment" in value;
    isInstance = isInstance && "educational" in value;
    isInstance = isInstance && "intendedStudy" in value;
    isInstance = isInstance && "scientificApproach" in value;
    isInstance = isInstance && "methodsDevelopment" in value;
    isInstance = isInstance && "otherPurpose" in value;
    isInstance = isInstance && "ethics" in value;
    isInstance = isInstance && "populationHealth" in value;
    isInstance = isInstance && "reviewRequested" in value;
    isInstance = isInstance && "socialBehavioral" in value;

    return isInstance;
}

export function ResearchPurposeFromJSON(json: any): ResearchPurpose {
    return ResearchPurposeFromJSONTyped(json, false);
}

export function ResearchPurposeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResearchPurpose {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aianResearchDetails': !exists(json, 'aianResearchDetails') ? undefined : json['aianResearchDetails'],
        'aianResearchType': !exists(json, 'aianResearchType') ? undefined : AIANResearchTypeFromJSON(json['aianResearchType']),
        'additionalNotes': !exists(json, 'additionalNotes') ? undefined : json['additionalNotes'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'ancestry': json['ancestry'],
        'anticipatedFindings': json['anticipatedFindings'],
        'commercialPurpose': json['commercialPurpose'],
        'controlSet': json['controlSet'],
        'diseaseFocusedResearch': json['diseaseFocusedResearch'],
        'diseaseOfFocus': !exists(json, 'diseaseOfFocus') ? undefined : json['diseaseOfFocus'],
        'drugDevelopment': json['drugDevelopment'],
        'educational': json['educational'],
        'intendedStudy': json['intendedStudy'],
        'scientificApproach': json['scientificApproach'],
        'methodsDevelopment': json['methodsDevelopment'],
        'otherPopulationDetails': !exists(json, 'otherPopulationDetails') ? undefined : json['otherPopulationDetails'],
        'otherPurpose': json['otherPurpose'],
        'otherPurposeDetails': !exists(json, 'otherPurposeDetails') ? undefined : json['otherPurposeDetails'],
        'ethics': json['ethics'],
        'populationDetails': !exists(json, 'populationDetails') ? undefined : ((json['populationDetails'] as Array<any>).map(SpecificPopulationEnumFromJSON)),
        'populationHealth': json['populationHealth'],
        'reasonForAllOfUs': !exists(json, 'reasonForAllOfUs') ? undefined : json['reasonForAllOfUs'],
        'reviewRequested': json['reviewRequested'],
        'socialBehavioral': json['socialBehavioral'],
        'timeRequested': !exists(json, 'timeRequested') ? undefined : json['timeRequested'],
        'timeReviewed': !exists(json, 'timeReviewed') ? undefined : json['timeReviewed'],
        'disseminateResearchFindingList': !exists(json, 'disseminateResearchFindingList') ? undefined : ((json['disseminateResearchFindingList'] as Array<any>).map(DisseminateResearchEnumFromJSON)),
        'otherDisseminateResearchFindings': !exists(json, 'otherDisseminateResearchFindings') ? undefined : json['otherDisseminateResearchFindings'],
        'researchOutcomeList': !exists(json, 'researchOutcomeList') ? undefined : ((json['researchOutcomeList'] as Array<any>).map(ResearchOutcomeEnumFromJSON)),
    };
}

export function ResearchPurposeToJSON(value?: ResearchPurpose | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aianResearchDetails': value.aianResearchDetails,
        'aianResearchType': AIANResearchTypeToJSON(value.aianResearchType),
        'additionalNotes': value.additionalNotes,
        'approved': value.approved,
        'ancestry': value.ancestry,
        'anticipatedFindings': value.anticipatedFindings,
        'commercialPurpose': value.commercialPurpose,
        'controlSet': value.controlSet,
        'diseaseFocusedResearch': value.diseaseFocusedResearch,
        'diseaseOfFocus': value.diseaseOfFocus,
        'drugDevelopment': value.drugDevelopment,
        'educational': value.educational,
        'intendedStudy': value.intendedStudy,
        'scientificApproach': value.scientificApproach,
        'methodsDevelopment': value.methodsDevelopment,
        'otherPopulationDetails': value.otherPopulationDetails,
        'otherPurpose': value.otherPurpose,
        'otherPurposeDetails': value.otherPurposeDetails,
        'ethics': value.ethics,
        'populationDetails': value.populationDetails === undefined ? undefined : ((value.populationDetails as Array<any>).map(SpecificPopulationEnumToJSON)),
        'populationHealth': value.populationHealth,
        'reasonForAllOfUs': value.reasonForAllOfUs,
        'reviewRequested': value.reviewRequested,
        'socialBehavioral': value.socialBehavioral,
        'timeRequested': value.timeRequested,
        'timeReviewed': value.timeReviewed,
        'disseminateResearchFindingList': value.disseminateResearchFindingList === undefined ? undefined : ((value.disseminateResearchFindingList as Array<any>).map(DisseminateResearchEnumToJSON)),
        'otherDisseminateResearchFindings': value.otherDisseminateResearchFindings,
        'researchOutcomeList': value.researchOutcomeList === undefined ? undefined : ((value.researchOutcomeList as Array<any>).map(ResearchOutcomeEnumToJSON)),
    };
}

